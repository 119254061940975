<div class="action-bar-container flex flex-col items-center py-2">
    <div class="flex flex-wrap gap-2">
        @for (group of groups; track $index) {
            @if ($index !== 0) {
                <div class="faded-separator w-0.5 self-stretch"></div>
            }
            <div class="flex flex-col items-center">
                <div class="flex flex-wrap gap-2">
                    @if (collapsed) {
                        <app-abdropdown-menu [label]="group.label" [items]="group.items"></app-abdropdown-menu>
                    } @else {
                        @for (item of group.items; track $index) {
                            <app-button
                                class="h-7"
                                size="md"
                                [variant]="item.variant"
                                [icon]="item.icon"
                                [iconOnly]="item.iconOnly"
                                [tooltip]="item.iconOnly ? item.label : null"
                                [disabled]="item.isDisabled"
                                (clicked)="item.onClick()">
                                {{ item.label | translate }}
                            </app-button>
                        }
                    }
                </div>
            </div>
        }
    </div>
    <!-- Remove '&& false' to enable the collapse feature -->
    @if (groups.length && false) {
        <div class="relative self-center">
            <button
                class="collapse-icon absolute top-[-6px] px-2 opacity-0 transition-opacity"
                (click)="toggleCollapsed()">
                <ng-icon class="rotate-icon" [name]="'faSolidAngleUp'" [class.rotate]="collapsed" />
            </button>
        </div>
    }
</div>
