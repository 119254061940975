import { Injector } from '@angular/core';
import { PaginatedFilter } from '@common/interfaces/query.interface';
import { Codelists } from './codelists.service';

export interface CodeLists {
    name: string;
}

export interface CodeListFilter extends PaginatedFilter {
    codelistName: string;
    name?: string;
    id?: string; // this is not the localizedCode !!
    sortAscending?: string;
    sortBy?: string;
    filterSearch?: string;
}

export interface CodeList {
    id: string;
    name: string;
    localizedCode: string;
}

export const fakedCodelists = {
    // these are entities that should be shown in in the codelists selection, but they are not technically codelists and are not editable in the codelist manager
    agent: 'Agent',
    callPurpose: 'CallPurpose',
    cargoAgent: 'CargoAgent',
    countryAlias: 'CountryAlias',
    countryNationality: 'CountryNationality',
    defect: 'Defect',
    draftVesselVisit: 'DraftVesselVisit',
    draftVesselVisitVersion: 'DraftVesselVisitVersion',
    lineAgent: 'LineAgent',
    localPort: 'LocalPort',
    organization: 'Organization',
    organizationUnit: 'OrganizationUnit',
    permission: 'Permission',
    railOperator: 'RailOperator',
    role: 'Role',
    shippingLine: 'ShippingLine',
    terminalOperator: 'TerminalOperator',
    user: 'User',
    vessel: 'Vessel',
    vesselVersion: 'VesselVersion',
    vesselType: 'VesselType',
    vesselTypeLevel1: 'VesselTypeLevel1',
    vesselTypeLevel2: 'VesselTypeLevel2',
    vesselTypeLevel3: 'VesselTypeLevel3',
    vesselTypeLevel4: 'VesselTypeLevel4',
    vesselTypeLevel5: 'VesselTypeLevel5',
    workflowState: 'WorkflowState'
} as const satisfies Record<string, string>;

export type FakedCodelists = (typeof fakedCodelists)[keyof typeof fakedCodelists];

export interface CodelistParams {
    name: Codelists | FakedCodelists;
    selectedIds?: string[];
    omittedIds?: string[];
    filter?: string;
    customFilter?: string;
    take?: number;
    useCache?: boolean;
    injector?: Injector;
}

// GetCodeListItemFullQuery
export interface CodeListItemFull {
    id: string;
    name?: string;
    localizedCode?: string;
    translations?: CodeListTranslation[];
    active?: boolean;
    version?: number;
    createdDate?: string;
    lastModifiedDate?: string;
    [key: string]: any;
}

export interface CodeListItemFullQuery {
    item: CodeListItemFull;
    properties: CodeListProperty[];
    default: CodeListItemFull;
}

export interface CodeListTranslation {
    localizedCode: string;
    languageId: string;
    language: Language;
    name: string;
    id: number;
}

export interface Language {
    name: string;
    localizedCode: string;
    active: boolean;
    version: number;
    createdDate: string;
    lastModifiedDate: string;
    id: string;
}

export interface CodeListProperty {
    key: string;
    label: string;
    dataType: 'datetime' | 'text' | 'number' | 'boolean' | 'codeList';
    codeListName: Codelists;
    defaultValue: any;
}

export interface CodeListItemFullFilter {
    codelistName: string;
    id: string;
}
// CodeListItemFullQuery above

export interface CodeListAddTranslationParameters {
    CodelistName: string;
    CodelistId: string;
    LanguageId: string;
    Name: string;
    LocalizedCode: string;
}

export interface TranslationsGridDataItem {
    name: string;
    localizedCode: string;
    languageId: string;
}

export interface CodeListRemoveTranslationParameters {
    CodelistName: string;
    CodelistId: string;
    Id: number;
    LanguageId: string;
}

export interface CodeListUpdateTranslationParameters {
    CodeListName: string;
    CodeListLanguageId: number;
    LanguageId: string;
    Name: string;
    LocalizedCode: string;
}

export type CodeListUpdateParameters = Omit<CodeListItemFullQuery, 'default'> & {
    codelistName: string;
    codeListId: string;
};

export interface CodeListCreateParameters {
    CodelistName: string;
    Item: CodeListItemFull;
}

export interface CodeListDeleteParameters {
    CodelistName: string;
    Id: string;
}
