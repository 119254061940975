<kendo-dialog-titlebar class="bg-background px-4 py-6 font-bold" (close)="close()">
    {{ options?.title | translate }}
</kendo-dialog-titlebar>
<div class="flex h-full flex-col justify-evenly gap-4 p-4">
    <div *ngIf="options.message" class="flex items-center">
        <div translate>
            <h6>{{ options.message | translate }}</h6>
        </div>
    </div>
    <div *ngIf="options.properties?.length > 0" class="grid grid-cols-12 gap-4">
        <ng-template ngFor let-property let-i="index" [ngForOf]="options.properties">
            <div ngClass="col-span-{{ property.colSpan || 12 }}">
                <app-control
                    [type]="property.type"
                    [label]="property.label"
                    [codelist]="property.codelist"
                    [time]="property.time === false ? false : true"
                    [multi]="property.multi"
                    [pattern]="property.pattern"
                    [format]="property.format"
                    [decimals]="property.decimals"
                    [valueField]="property.valueField || 'value'"
                    [isDisabled]="!property.isDisabled ? false : property.isDisabled(model)"
                    [filter]="property.filter?.bind(this)"
                    [required]="property.required"
                    [options]="property.options ?? []"
                    [disabledDates]="property.disabledDates"
                    [placeholder]="property.placeholder"
                    [rows]="property.rows || 3"
                    [nullable]="property.nullable"
                    [minDate]="property.minDate"
                    [maxDate]="property.maxDate"
                    [(ngModel)]="model[getName(property)]" />
            </div>
        </ng-template>
    </div>
</div>
<ng-template #template></ng-template>
<kendo-dialog-actions
    class="px-4"
    layout="end"
    [ngStyle]="{
        display: !canEditPredicate(model) ? 'none' : 'inherit'
    }">
    <div class="flex w-full flex-col justify-between sm:flex-row sm:items-center">
        <div class="mb-2 flex-grow sm:mb-0">
            @if (options.confirmMessage) {
                <span class="text-xs font-bold">{{ options.confirmMessage | translate }}</span>
            } @else {
                <div></div>
            }
        </div>
        <div class="flex justify-end space-x-4 sm:space-x-2">
            <app-button variant="secondary" [icon]="options?.cancelIcon" (click)="clear()">
                {{ options?.cancelText | translate }}
            </app-button>
            <app-button
                variant="primary"
                [icon]="options?.confirmIcon"
                [disabled]="!canConfirmPredicate(model)"
                (click)="confirmAction()">
                {{ options?.confirmText | translate }}
            </app-button>
        </div>
    </div>
</kendo-dialog-actions>
