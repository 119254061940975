@if (column.isLink) {
    @if (column.click) {
        <div class="cursor-pointer text-link" (click)="column.click(row)">
            {{ row[column.field] }}
        </div>
    } @else {
        @if (column.customRoute) {
            <app-link
                [permission]="column.customRoute.permission"
                [route]="[column.customRoute.path, defaultViewMode, row[column.customRoute.field]]">
                {{ row[column.field] }}
            </app-link>
        } @else {
            <app-link [checkPermission]="false" [route]="[parentRoute, defaultViewMode, row?.id]">
                {{ row[column.field] }}
            </app-link>
        }
    }
} @else if (column.click && !column.icon) {
    <app-link [checkPermission]="false" (click)="column.click(row)">
        {{ row[column.field] }}
    </app-link>
} @else if (column.type === 'codelist') {
    <ng-container *ngIf="row[column.field] | codelist: column.codelistName | async as codelist">
        @if (fakedCodelistArray.includes(column.codelistName)) {
            {{ codelist }}
        } @else {
            @if (column.codelistTooltip) {
                @if (column.codelistPill) {
                    @if (row[column.field]) {
                        <div class="flex flex-row items-end justify-between gap-2" kendoTooltip>
                            <div
                                class="rounded-xl px-4 py-1 text-center text-xs"
                                title="{{ codelist }}"
                                [pill]="{ status: row[column.field], module: column.codelistName }">
                                {{ row[column.field] }}
                            </div>
                            <ng-content />
                        </div>
                    }
                } @else {
                    <span kendoTooltip title="{{ codelist }}">
                        {{ row[column.field] }}
                    </span>
                }
            } @else {
                @if (column.codelistPill) {
                    <div
                        class="rounded-xl px-4 py-1 text-center"
                        [pill]="{ status: row[column.field], module: column.codelistName }">
                        {{ row[column.field] }}
                    </div>
                } @else {
                    {{ row[column.field] }}
                }
            }
        }
    </ng-container>
} @else if (column.type === 'select') {
    <app-control type="select" [isEditMode]="false" [options]="column.options" [ngModel]="row[column.field]" />
} @else if (column.type === 'boolean' && column.field === 'active') {
    <span
        *ngIf="row[column.field]"
        class="inline-block h-3 w-3 rounded-full bg-success"
        kendoTooltip
        title="{{ 'Active' | translate }}"></span>
} @else if (column.type === 'boolean' && !column.icon) {
    <app-control type="boolean" [ngModel]="row[column.field]" [isDisabled]="true"></app-control>
} @else if (column.type === 'boolean' && column.icon) {
    <ng-icon *ngIf="row[column.field]" [name]="column.icon.toString()" [ngClass]="column.iconClass"></ng-icon>
} @else if (column.type !== 'boolean' && column.icon) {
    <ng-container>
        {{ row[column.field] }}
        <ng-icon [name]="column.icon.toString()" [ngClass]="column.iconClass" (click)="column.click(row)"></ng-icon>
    </ng-container>
} @else if (column.type === 'yesno') {
    @if (row[column.field] === true) {
        <span>{{ 'Yes' | translate }}</span>
    } @else if (row[column.field] === false) {
        <span>{{ 'No' | translate }}</span>
    } @else {
        <span>-</span>
    }
} @else if (column.type === 'date') {
    <span>
        <span>{{ row[column.field] | customDate }}</span>
    </span>
} @else if (column.type === 'datetime') {
    <span>
        <span>{{ row[column.field] | datetime }}</span>
    </span>
} @else if (column.isDpgClass) {
    @switch (row.cargoRegulationId) {
        @case ('IBC') {
            <span kendoTooltip title="{{ row.ibcCodeId | codelist: 'IbcCode' | async }}">
                {{ row.ibcCodeId }}
            </span>
        }
        @case ('IMSBC') {
            <span kendoTooltip title="{{ row.imsbcCodeId | codelist: 'ImsbcCode' | async }}">
                {{ row.imsbcCodeId }}
            </span>
        }
        @default {
            <span kendoTooltip title="{{ row.imdgClassId | codelist: 'ImdgClass' | async }}">
                {{ row.imdgClassId }}
            </span>
        }
    }
} @else if (column.type === 'file') {
    <app-upload-action-buttons [file]="row[column.field]" [disabled]="true" />
} @else if (column.type === 'number') {
    @if (column.format === 'n3') {
        <span>{{ row[column.field] | number: '1.3-3' : 'en-SI' }}</span>
    } @else {
        <span>{{ row[column.field] | number }}</span>
    }
} @else if (column.type === 'flag') {
    <app-flag-icon codelist="Country" [code]="row[column.field]"></app-flag-icon>
} @else if (column.type === 'vesselType') {
    <app-vessel-type [model]="row[column.field]" />
} @else if (column.type === 'seal') {
    <app-seal [model]="row[column.field]" />
} @else if (column.type === 'mots') {
    <app-mean-of-transport [model]="row[column.field]" />
} @else {
    @if (column.maxLength && row[column.field]?.length > column.maxLength) {
        <span kendoTooltip title="{{ row[column.field] }}">
            {{ row[column.field].substring(0, column.maxLength) + '...' }}
        </span>
    } @else {
        <span>
            {{ row[column.field] }}
        </span>
    }
}
