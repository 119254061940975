import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { formatCharacterCount } from '../input.helpers';
import { TextInputConfig } from '../input.type';

/**
 * Text input component.
 * @param maxLength - The maximum length of the text.
 * @param minLength - The minimum length of the text.
 * @param pattern - The pattern of the text.
 */
@Component({
    selector: 'app-text-input',
    templateUrl: './text-input.component.html',
    styleUrls: ['./text-input.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [{ provide: BaseInputComponent, useExisting: TextInputComponent }]
})
export class TextInputComponent extends BaseInputComponent<string> implements OnInit {
    @Input() maxLength: TextInputConfig['maxLength'];
    @Input() minLength: TextInputConfig['minLength'];
    @Input() pattern: TextInputConfig['pattern'];
    @Input() password: TextInputConfig['password'] = false;
    characterCount: string;

    ngOnInit(): void {
        if (!this.value()) {
            // This is to avoid displaying 'undefined' when the value passed in is undefined eg.: model.username, where username is undefined.
            this.value.set(null);
        }
        this.characterCount = formatCharacterCount(this.maxLength, this.value());
    }

    override onValueChange(value: string): void {
        super.onValueChange(value);
        this.characterCount = formatCharacterCount(this.maxLength, value);
    }

    onKeyPress(event: KeyboardEvent) {
        if (!this.pattern) return;
        if (!new RegExp(this.pattern).test(event.key)) event.preventDefault();
    }
}
