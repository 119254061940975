@if (column.type === 'codelist') {
    <app-control
        type="codelist"
        [codelist]="column.codelistName"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'select') {
    <app-control
        type="select"
        [options]="column.options"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'boolean') {
    <app-control
        type="boolean"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'yesno') {
    <app-control
        type="yesno"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'date') {
    <app-control
        type="date"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'datetime') {
    <app-control
        type="datetime"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'number') {
    <app-control
        type="number"
        [format]="column.format"
        [min]="column.min"
        [decimals]="column.decimals"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else if (column.type === 'file') {
    <app-control
        type="file"
        [required]="column.required"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
} @else {
    <app-control
        [required]="column.required"
        [maxlength]="column.maxLength"
        [(ngModel)]="row[column.field]"
        (ngModelChange)="column.ngModelChange?.(row, row[column.field])"></app-control>
}
