import {
    faSolidAnchor,
    faSolidAngleLeft,
    faSolidAngleRight,
    faSolidAngleUp,
    faSolidAnglesLeft,
    faSolidAnglesRight,
    faSolidArrowRightArrowLeft,
    faSolidArrowRightFromBracket,
    faSolidArrowRightToBracket,
    faSolidArrowRotateLeft,
    faSolidArrowsRotate,
    faSolidArrowsTurnToDots,
    faSolidBan,
    faSolidBars,
    faSolidBatteryEmpty,
    faSolidBatteryFull,
    faSolidBell,
    faSolidBellSlash,
    faSolidBook,
    faSolidBox,
    faSolidBoxArchive,
    faSolidBoxOpen,
    faSolidBoxesStacked,
    faSolidBuilding,
    faSolidBullhorn,
    faSolidCalendarCheck,
    faSolidCar,
    faSolidCaretDown,
    faSolidCaretUp,
    faSolidChartBar,
    faSolidCheck,
    faSolidCircle,
    faSolidCircleCheck,
    faSolidCircleExclamation,
    faSolidCircleInfo,
    faSolidCirclePlay,
    faSolidCircleQuestion,
    faSolidCircleStop,
    faSolidCircleUser,
    faSolidCircleXmark,
    faSolidClipboardList,
    faSolidClone,
    faSolidCompress,
    faSolidCube,
    faSolidDatabase,
    faSolidDownload,
    faSolidEnvelope,
    faSolidEnvelopeCircleCheck,
    faSolidEnvelopeOpen,
    faSolidExclamation,
    faSolidExpand,
    faSolidEye,
    faSolidFile,
    faSolidFileCsv,
    faSolidFileExcel,
    faSolidFileInvoiceDollar,
    faSolidFileLines,
    faSolidFilePdf,
    faSolidFilter,
    faSolidFilterCircleXmark,
    faSolidFire,
    faSolidFireFlameCurved,
    faSolidFloppyDisk,
    faSolidGauge,
    faSolidGear,
    faSolidHammer,
    faSolidHand,
    faSolidHouse,
    faSolidInbox,
    faSolidKey,
    faSolidLanguage,
    faSolidLightbulb,
    faSolidList,
    faSolidListCheck,
    faSolidLocationDot,
    faSolidLockOpen,
    faSolidMagnifyingGlass,
    faSolidMoon,
    faSolidPaperclip,
    faSolidPassport,
    faSolidPenToSquare,
    faSolidPersonMilitaryPointing,
    faSolidPlaneArrival,
    faSolidPlaneDeparture,
    faSolidPlus,
    faSolidPrint,
    faSolidQuestion,
    faSolidScrewdriverWrench,
    faSolidShare,
    faSolidShieldHalved,
    faSolidShip,
    faSolidStop,
    faSolidSun,
    faSolidTableColumns,
    faSolidTags,
    faSolidThumbsDown,
    faSolidThumbsUp,
    faSolidThumbtack,
    faSolidTrain,
    faSolidTrash,
    faSolidTruck,
    faSolidTruckRampBox,
    faSolidUpload,
    faSolidUser,
    faSolidUserPlus,
    faSolidUsers,
    faSolidWarehouse,
    faSolidXmark
} from '@ng-icons/font-awesome/solid';

import { bootstrapApple, bootstrapFacebook, bootstrapGoogle } from '@ng-icons/bootstrap-icons';

const icons: Record<string, string> = {
    bootstrapApple,
    bootstrapFacebook,
    bootstrapGoogle,
    faSolidAnchor,
    faSolidAngleLeft,
    faSolidAngleRight,
    faSolidAnglesLeft,
    faSolidAnglesRight,
    faSolidAngleUp,
    faSolidArrowRightArrowLeft,
    faSolidArrowRightFromBracket,
    faSolidArrowRightToBracket,
    faSolidArrowRotateLeft,
    faSolidArrowsRotate,
    faSolidArrowsTurnToDots,
    faSolidBan,
    faSolidBars,
    faSolidBatteryEmpty,
    faSolidBatteryFull,
    faSolidBell,
    faSolidBellSlash,
    faSolidBook,
    faSolidBox,
    faSolidBoxArchive,
    faSolidBoxOpen,
    faSolidBoxesStacked,
    faSolidBuilding,
    faSolidBullhorn,
    faSolidCar,
    faSolidCaretDown,
    faSolidCaretUp,
    faSolidCalendarCheck,
    faSolidChartBar,
    faSolidCheck,
    faSolidCircle,
    faSolidCircleCheck,
    faSolidCircleExclamation,
    faSolidCircleInfo,
    faSolidCircleQuestion,
    faSolidCirclePlay,
    faSolidCircleStop,
    faSolidCircleUser,
    faSolidCircleXmark,
    faSolidClipboardList,
    faSolidClone,
    faSolidCompress,
    faSolidCube,
    faSolidDatabase,
    faSolidDownload,
    faSolidEnvelope,
    faSolidEnvelopeCircleCheck,
    faSolidEnvelopeOpen,
    faSolidExclamation,
    faSolidExpand,
    faSolidEye,
    faSolidFile,
    faSolidFileExcel,
    faSolidFileCsv,
    faSolidFileInvoiceDollar,
    faSolidFileLines,
    faSolidFilePdf,
    faSolidFilter,
    faSolidFilterCircleXmark,
    faSolidFire,
    faSolidFireFlameCurved,
    faSolidFloppyDisk,
    faSolidGauge,
    faSolidGear,
    faSolidHammer,
    faSolidHand,
    faSolidHouse,
    faSolidInbox,
    faSolidKey,
    faSolidLanguage,
    faSolidLightbulb,
    faSolidList,
    faSolidListCheck,
    faSolidLocationDot,
    faSolidLockOpen,
    faSolidMagnifyingGlass,
    faSolidMoon,
    faSolidPaperclip,
    faSolidPassport,
    faSolidPenToSquare,
    faSolidPersonMilitaryPointing,
    faSolidPlaneArrival,
    faSolidPlaneDeparture,
    faSolidPlus,
    faSolidPrint,
    faSolidQuestion,
    faSolidScrewdriverWrench,
    faSolidShare,
    faSolidShieldHalved,
    faSolidShip,
    faSolidStop,
    faSolidSun,
    faSolidTableColumns,
    faSolidTags,
    faSolidThumbsDown,
    faSolidThumbsUp,
    faSolidThumbtack,
    faSolidTrain,
    faSolidTrash,
    faSolidTruck,
    faSolidTruckRampBox,
    faSolidUpload,
    faSolidUser,
    faSolidUserPlus,
    faSolidUsers,
    faSolidWarehouse,
    faSolidXmark
};

export default icons;

export type IconKey =
    | 'bootstrapApple'
    | 'bootstrapFacebook'
    | 'bootstrapGoogle'
    | 'faSolidAnchor'
    | 'faSolidAngleLeft'
    | 'faSolidAngleRight'
    | 'faSolidAnglesLeft'
    | 'faSolidAnglesRight'
    | 'faSolidAngleUp'
    | 'faSolidArrowRightArrowLeft'
    | 'faSolidArrowRightFromBracket'
    | 'faSolidArrowRightToBracket'
    | 'faSolidArrowRotateLeft'
    | 'faSolidArrowsRotate'
    | 'faSolidArrowsTurnToDots'
    | 'faSolidBan'
    | 'faSolidBars'
    | 'faSolidBatteryEmpty'
    | 'faSolidBatteryFull'
    | 'faSolidBell'
    | 'faSolidBellSlash'
    | 'faSolidBook'
    | 'faSolidBox'
    | 'faSolidBoxArchive'
    | 'faSolidBoxOpen'
    | 'faSolidBoxesStacked'
    | 'faSolidBuilding'
    | 'faSolidBullhorn'
    | 'faSolidCar'
    | 'faSolidCaretDown'
    | 'faSolidCaretUp'
    | 'faSolidCalendarCheck'
    | 'faSolidChartBar'
    | 'faSolidCheck'
    | 'faSolidCircle'
    | 'faSolidCircleCheck'
    | 'faSolidCircleExclamation'
    | 'faSolidCircleInfo'
    | 'faSolidCircleQuestion'
    | 'faSolidCirclePlay'
    | 'faSolidCircleStop'
    | 'faSolidCircleUser'
    | 'faSolidCircleXmark'
    | 'faSolidClipboardList'
    | 'faSolidClone'
    | 'faSolidCompress'
    | 'faSolidCube'
    | 'faSolidDatabase'
    | 'faSolidDownload'
    | 'faSolidEnvelope'
    | 'faSolidEnvelopeCircleCheck'
    | 'faSolidEnvelopeOpen'
    | 'faSolidExclamation'
    | 'faSolidExpand'
    | 'faSolidEye'
    | 'faSolidFile'
    | 'faSolidFileExcel'
    | 'faSolidFileCsv'
    | 'faSolidFileInvoiceDollar'
    | 'faSolidFileLines'
    | 'faSolidFilePdf'
    | 'faSolidFilter'
    | 'faSolidFilterCircleXmark'
    | 'faSolidFire'
    | 'faSolidFireFlameCurved'
    | 'faSolidFloppyDisk'
    | 'faSolidGauge'
    | 'faSolidGear'
    | 'faSolidHammer'
    | 'faSolidHand'
    | 'faSolidHouse'
    | 'faSolidInbox'
    | 'faSolidKey'
    | 'faSolidLanguage'
    | 'faSolidLightbulb'
    | 'faSolidList'
    | 'faSolidListCheck'
    | 'faSolidLocationDot'
    | 'faSolidLockOpen'
    | 'faSolidMagnifyingGlass'
    | 'faSolidMoon'
    | 'faSolidPaperclip'
    | 'faSolidPassport'
    | 'faSolidPenToSquare'
    | 'faSolidPersonMilitaryPointing'
    | 'faSolidPlaneArrival'
    | 'faSolidPlaneDeparture'
    | 'faSolidPlus'
    | 'faSolidPrint'
    | 'faSolidQuestion'
    | 'faSolidScrewdriverWrench'
    | 'faSolidShare'
    | 'faSolidShieldHalved'
    | 'faSolidShip'
    | 'faSolidStop'
    | 'faSolidSun'
    | 'faSolidTableColumns'
    | 'faSolidTags'
    | 'faSolidThumbsDown'
    | 'faSolidThumbsUp'
    | 'faSolidThumbtack'
    | 'faSolidTrain'
    | 'faSolidTrash'
    | 'faSolidTruck'
    | 'faSolidTruckRampBox'
    | 'faSolidUpload'
    | 'faSolidUser'
    | 'faSolidUserPlus'
    | 'faSolidUsers'
    | 'faSolidWarehouse'
    | 'faSolidXmark';
