<kendo-dialog-titlebar class="mb-2 bg-background px-4 py-6 font-bold" translate (close)="close()">
    {{ 'New User' | translate }}
</kendo-dialog-titlebar>
<app-stepper
    stepType="full"
    [linear]="false"
    [steps]="steps"
    [validateStep]="validateStep"
    [(currentStep)]="currentStep" />
@if (currentStep() === 0) {
    <div class="m-4 flex flex-col gap-4">
        <div class="l:grid-cols-4 grid grid-cols-1 gap-4 md:grid-cols-2 xl:grid-cols-4">
            <app-control
                label="Full Name"
                [required]="true"
                [(ngModel)]="model.fullName"
                (customBlur)="onFullNameBlur()" />
            <app-control label="Username" [required]="true" [(ngModel)]="model.userName" />
            <app-control
                label="Password"
                type="password"
                autocomplete="new-password"
                [required]="true"
                [(ngModel)]="model.password" />
            <app-control
                label="Organization"
                type="codelist"
                codelist="Organization"
                [required]="true"
                [(ngModel)]="model.organizationId" />
            <app-control label="E-mail" pattern="[\w\d\.\-\_\@]" [maxLength]="255" [(ngModel)]="model.email" />
            <app-control label="Phone Number" pattern="^[0-9]*$" [(ngModel)]="model.phoneNumber" />
            <app-control
                label="Language"
                type="codelist"
                codelist="Language"
                [required]="true"
                [(ngModel)]="model.languageId" />
        </div>
    </div>
} @else if (currentStep() === 1) {
    <app-editable-grid
        [data]="model.attachments"
        [filterable]="false"
        [editMode]="true"
        [cols]="attachmentColumns"
        [validateRow]="validateAttachmentRow"
        [initialData]="{ active: true }" />
}
<kendo-dialog-actions class="flex justify-between px-4">
    <div class="flex-1">
        <app-button variant="secondary" icon="faSolidXmark" (click)="close()">
            {{ 'Cancel' | translate }}
        </app-button>
    </div>
    <div class="flex flex-1 justify-center">
        @if (currentStep() !== 0) {
            <app-button variant="secondary" icon="faSolidAnglesLeft" (click)="stepper().onStepChange(-1)">
                {{ 'Previous' | translate }}
            </app-button>
        }
        @if (currentStep() < steps.length - 1) {
            <app-button
                variant="secondary"
                icon="faSolidAnglesRight"
                iconPosition="end"
                (click)="stepper().onStepChange(1)">
                {{ 'Next' | translate }}
            </app-button>
        }
    </div>
    <div class="flex flex-1 justify-end">
        <app-button variant="secondary" icon="faSolidCheck" iconPosition="end" (click)="confirm()">
            {{ 'Create' | translate }}
        </app-button>
    </div>
</kendo-dialog-actions>
