import { Component, Input } from '@angular/core';
import { BaseListComponent } from '@common/classes/base-list';
import { ColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';

@Component({
    selector: 'app-approval-tab',
    templateUrl: './approval-tab.component.html',
    styleUrls: ['./approval-tab.component.scss'],
    providers: [CommonService]
})
export class ApprovalTabComponent extends BaseListComponent {
    @Input() entityId: any;
    @Input() entityType: any;
    @Input() statusId: any;
    @Input() serviceTypeId: string;
    @Input() shipmentTypeId: string;

    override queryName = 'WorkflowApprovals';

    override columns: ColumnSettings[] = [
        {
            field: 'title',
            title: 'Title'
        },
        {
            field: 'organizationId',
            title: 'Organization',
            type: 'codelist',
            codelistName: 'Organization'
        },
        {
            field: 'decision',
            title: 'Decision'
        },
        {
            field: 'date',
            title: 'Date',
            type: 'datetime'
        },
        {
            field: 'remark',
            title: 'Remark'
        },
        {
            field: 'sequenceNumber',
            title: 'Seq Nr.'
        }
    ];

    constructor(commonService: CommonService) {
        super(commonService);
    }

    override getDefaultFilter(): {} {
        return {
            entityId: this.entityId,
            entityType: this.entityType,
            statusId: this.statusId === 'REJ' ? 'REJ' : 'APP'
        };
    }
}
