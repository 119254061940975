import { FakedCodelists } from '@administration/codelists/codelists.interface';
import { Codelists } from '@administration/codelists/codelists.service';
import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CodeList } from '@common/classes/codelist';
import { CommandParams } from '@common/interfaces/command-params.interface';
import { defaultSelectLabel } from '@common/models/util';
import { ViewMode } from '@common/models/view-mode';
import { DialogService } from '@common/services/dialog.service';
import { mapResultData, QueryObserverResult, tapSuccessResult } from '@ngneat/query';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { filter, take } from 'rxjs';
import { DarkModeService } from './dark-mode.service';
import { I18nService } from './i18n.service';
import { NavigationService } from './navigation.service';
import { QueryService } from './query.service';
import { RememberStateService } from './remember-state.service';
import { SettingsService } from './settings.service';
import { ToastrNotificationService } from './toastr-notification.service';
import { UserService } from './user.service';

@Injectable()
export class CommonService {
    constructor(
        public toastrNotificationService: ToastrNotificationService,
        public translateService: TranslateService,
        public router: Router,
        public activatedRoute: ActivatedRoute,
        public userService: UserService,
        public dialogService: DialogService,
        public queryService: QueryService,
        public rememberStateService: RememberStateService,
        public http: HttpClient,
        public i18nService: I18nService,
        public darkModeService: DarkModeService,
        public settingsService: SettingsService,
        public injector: Injector,
        public navigationService: NavigationService,
        private spinner: NgxSpinnerService
    ) {}

    isViewMode() {
        return this.activatedRoute.snapshot.data.mode === ViewMode.view;
    }

    isEditMode() {
        return !this.isViewMode();
    }

    getCodelistItems(
        name: Codelists | FakedCodelists,
        filter: string = null,
        customFilter: string = null,
        selectLabel = (item: CodeList) => defaultSelectLabel(item)
    ) {
        return this.queryService
            .getCodelistQuery<any>({
                name,
                filter,
                customFilter,
                injector: this.injector
            })
            .result$.pipe(mapResultData((res) => res.map((g) => ({ value: g.id, label: selectLabel(g) }))));
    }

    async executeCommand(params: CommandParams): Promise<any> {
        const {
            command,
            data,
            customCallback,
            invalidate,
            successToastrMessage = 'Success',
            showSpinner = false
        } = params;
        if (showSpinner) this.spinner.show();
        return this.queryService
            .getCommandMutation()
            .mutateAsync({ command, data, invalidate })
            .then(async (data) => {
                this.toastrNotificationService.show({ type: 'success', message: successToastrMessage });
                if (customCallback) await customCallback(data);
                return data;
            })
            .finally(() => {
                if (showSpinner) this.spinner.hide();
            });
    }

    async executeWorkflowCommand(params: CommandParams): Promise<any> {
        const { command, data, customCallback, invalidate, showSpinner = false } = params;
        const title = command.replace(/([A-Z])/g, ' $1').trim();
        const actionTypeId = this.getActionType(title.split(' ')[0]);
        const { stateFromCode, entityType } = data;
        return this.queryService
            .getQuery(
                'WorkflowConditions',
                { actionTypeId, stateFromCode, entityType },
                { injector: this.injector, refetchOnWindowFocus: false }
            )
            .result$.pipe(
                filter((x: QueryObserverResult) => x.isSuccess),
                take(1),
                tapSuccessResult<any>(async (transition) => {
                    data.actionTypeId = actionTypeId;
                    if (transition?.remarkAllowed) {
                        const res = await this.dialogService.form({
                            options: {
                                title,
                                properties: [
                                    {
                                        name: 'remarks',
                                        type: 'textarea',
                                        label: 'Remarks',
                                        required: transition.remarkRequired
                                    }
                                ]
                            }
                        });
                        if (!res) return null;
                        data.remarks = res.remarks;
                    }
                    if (showSpinner) this.spinner.show();
                    return this.queryService
                        .getCommandMutation()
                        .mutateAsync({ command, data, invalidate })
                        .then(async (data) => {
                            this.toastrNotificationService.show({ type: 'success', message: 'Success' });
                            if (customCallback) await customCallback(data);
                            return data;
                        })
                        .finally(() => {
                            if (showSpinner) this.spinner.hide();
                        });
                })
            )
            .subscribe();
    }

    getSanitizedUrl(filter?: any): string {
        let url = this.router.url;
        const id = this.activatedRoute.snapshot.paramMap.get('id');
        const mode = this.activatedRoute.snapshot.data.mode;

        if (!(id && mode)) return url;

        url = url.replace(`/${mode}`, '').replace(`/${id}`, '');
        if (filter) url += `?filter=${JSON.stringify(filter)}`;
        return url;
    }

    private getActionType(command: string): string {
        switch (command) {
            case 'Approve':
                return 'APP';
            case 'Cancel':
                return 'CAN';
            case 'Complete':
                return 'COM';
            case 'Release':
                return 'RLS';
            case 'Reject':
                return 'REJ';
            case 'Reuse':
                return 'RTD';
            case 'Send':
                return 'SND';
            default:
                return null;
        }
    }
}
