import { Directive, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
    selector: '[pill]'
})
export class PillDirective implements OnChanges {
    // Colors
    dark = '#222';
    light = '#fff';
    grey = '#CED4DA';
    yellow = '#FFC107';
    amber = '#FFC000';
    orange = '#FD9A47';
    green = '#5DD879';
    lightblue = '#ADD8E6';
    blue = '#59A9FF';
    darkgrey = '#666';
    red = '#E56B77';

    // Status, module & muted input
    @Input() pill: { status: string; module: string };

    el: ElementRef;

    constructor(el: ElementRef) {
        this.el = el;
    }

    ngOnChanges() {
        const backgroundColor = this.pillColor(this.pill);
        this.el.nativeElement.style.backgroundColor = backgroundColor;
        this.el.nativeElement.style.color = this.backgroundToForeground(backgroundColor);
    }

    private backgroundToForeground(color: string): string {
        return [this.dark, this.darkgrey].includes(color) ? this.light : this.dark;
    }

    private pillColor(input: any): string {
        const status = input.status?.toUpperCase();

        switch (status) {
            case 'TB':
            case 'ANN':
            case 'DRA':
                return this.yellow;

            case 'RFU':
                return this.amber;

            case 'BU':
            case 'WFA':
            case 'RTE':
                return this.orange;

            case 'AC':
            case 'APP':
            case 'RLS':
            case 'INP':
                return this.green;

            case 'ARR':
            case 'WIP':
            case 'RTL':
                return this.lightblue;

            case 'COM':
            case 'DEP':
            case 'LTP':
                return this.blue;

            case 'EX':
            case 'DT':
            case 'CAN':
                return this.darkgrey;

            case 'BN':
            case 'IA':
            case 'REJ':
                return this.red;
        }

        return this.grey;
    }
}
