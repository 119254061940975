import { Component, Signal, signal, viewChild, viewChildren } from '@angular/core';
import { AppControlComponent } from '@common/components/app-control/app-control.component';
import { Step, StepperComponent } from '@common/components/stepper/stepper.component';
import { EditableColumnSettings } from '@common/interfaces/column-settings.interface';
import { CommonService } from '@common/services/common.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';

@Component({
    selector: 'app-self-registration-modal',
    templateUrl: './self-registration-modal.component.html'
})
export class SelfRegistrationModalComponent extends DialogContentBase {
    model: any = { attachments: [] };

    currentStep = signal(0);

    stepper: Signal<StepperComponent> = viewChild.required(StepperComponent);
    appControls: Signal<readonly AppControlComponent[]> = viewChildren(AppControlComponent);

    steps: Step[];

    attachmentColumns: EditableColumnSettings[] = [
        {
            field: 'file',
            title: 'File',
            type: 'file',
            required: true,
            width: null
        },
        {
            field: 'description',
            title: 'File Description',
            width: 250
        }
    ];

    constructor(
        public override dialog: DialogRef,
        private common: CommonService
    ) {
        super(dialog);
        this.setSteps();
    }

    setSteps() {
        this.steps = [{ label: 'User Info' }, { label: 'Attachments' }];
    }

    confirm(): void {
        if (!this.validateStep()) return;
        this.dialog.close(this.model);
    }

    close(): void {
        this.dialog.close(null);
    }

    validateStep = (): boolean => {
        if (this.appControls().some((x) => !!x.error)) {
            this.common.toastrNotificationService.show({
                type: 'error',
                message: 'Some required fields are empty'
            });
            this.stepper().currentStep.set(this.currentStep());
            return false;
        }
        return true;
    };

    onFullNameBlur(): void {
        if (this.model.fullName) {
            this.common.queryService
                .getCommandMutation()
                .mutateAsync({
                    command: 'GenerateUsername',
                    data: { fullName: this.model.fullName }
                })
                .then((res: any) => (this.model.userName = res?.userName));
        }
    }

    validateAttachmentRow = (dataItem: any) => dataItem.file;
}
