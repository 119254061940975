import {
    AfterViewInit,
    Component,
    ComponentRef,
    Injector,
    OnDestroy,
    StaticProvider,
    Type,
    ViewChild,
    ViewChildren,
    ViewContainerRef
} from '@angular/core';
import { IconKey } from '@common/classes/icons';
import { ToastrNotificationService } from '@common/services/toastr-notification.service';
import { DialogContentBase, DialogRef } from '@progress/kendo-angular-dialog';
import _ from 'lodash';
import { AppControlComponent, FormProperty } from '../app-control/app-control.component';

export interface DialogFormOptions {
    title: string;
    message?: string;
    model?: any;
    cancelText?: string;
    cancelIcon?: IconKey;
    cancelAction?: Function;
    confirmText?: string;
    confirmIcon?: IconKey;
    confirmMessage?: string;
    properties?: FormProperty[];
    canEdit?: (model: any) => boolean;
    canConfirm?: (model: any) => boolean;
    contentComponent?: Type<any>;
    contentComponentDataProviders?: StaticProvider[];
    onContentComponentCreated?: (component: any) => void;
}

@Component({
    selector: 'app-dialog-form',
    templateUrl: './dialog-form.component.html'
})
export class DialogFormComponent extends DialogContentBase implements OnDestroy, AfterViewInit {
    @ViewChild('template', { static: false, read: ViewContainerRef }) templateRef: ViewContainerRef;

    private contentComponentRef: ComponentRef<any>;
    private injector: Injector;

    model: any;
    options: DialogFormOptions = {
        title: 'Form',
        cancelText: 'Cancel',
        confirmText: 'Confirm'
    };
    confirmAction: Function;
    canConfirmPredicate: (model: any) => boolean = () => true;
    canEditPredicate: (model: any) => boolean = () => true;
    cancelAction: Function;

    @ViewChildren(AppControlComponent) appControls: AppControlComponent[];

    constructor(
        public override dialog: DialogRef,
        private toastrNotificationService: ToastrNotificationService
    ) {
        super(dialog);
    }

    override ngAfterViewInit(): void {
        if (this.options.contentComponent) {
            this.loadComponent(this.options.contentComponent, this.options.contentComponentDataProviders);
        }

        this.confirmAction = this.confirmAction || this.confirm.bind(this);
        this.cancelAction = this.cancelAction || this.close.bind(this);
    }

    ngOnDestroy(): void {
        if (this.contentComponentRef) {
            this.contentComponentRef.destroy();
        }
    }

    public initialize(options: DialogFormOptions, injector: Injector): void {
        for (const [key, value] of Object.entries(options)) {
            if (value != null) this.options[key] = value;
        }

        if (this.options.cancelAction) this.cancelAction = this.options.cancelAction;
        this.model = this.options.model || {};
        this.options.properties?.forEach((prop) => (this.model[prop.name] = prop.initialValue));
        this.injector = injector;
        this.canConfirmPredicate = this.options.canConfirm || this.canConfirm.bind(this);
        this.canEditPredicate = this.options.canEdit || this.canEdit.bind(this);
    }

    confirm(): void {
        if (this.appControls.some((x) => !!x.error)) {
            this.toastrNotificationService.show({ type: 'error', message: 'Some required fields are empty' });
            return;
        }
        this.dialog.close(this.model);
    }

    canConfirm(model: any): boolean {
        return true;
    }

    canEdit(model: any): boolean {
        return true;
    }

    close(): void {
        this.dialog.close(null);
    }

    clear(): void {
        if (this.cancelAction) this.cancelAction();
        this.model = {};
    }

    getName(property: FormProperty): string {
        return property.name;
    }

    private loadComponent(component, dataProviders: StaticProvider[]): void {
        dataProviders = (dataProviders || []).concat([{ provide: DialogRef, useValue: this.dialog }]);
        const injector = Injector.create({ providers: dataProviders || [], parent: this.injector });
        this.contentComponentRef = this.templateRef.createComponent(component, { injector });
        if (!this.contentComponentRef.instance.model) {
            this.contentComponentRef.instance.model = this.model;
        } else {
            this.model = this.contentComponentRef.instance.model;
        }

        if (_.isFunction(this.contentComponentRef.instance.confirm)) {
            this.confirmAction = this.contentComponentRef.instance.confirm.bind(this.contentComponentRef.instance);
        }

        if (_.isFunction(this.contentComponentRef.instance.canConfirm)) {
            this.canConfirmPredicate = this.contentComponentRef.instance.canConfirm.bind(
                this.contentComponentRef.instance
            );
        }

        if (_.isFunction(this.contentComponentRef.instance.canEdit)) {
            this.canEditPredicate = this.contentComponentRef.instance.canEdit.bind(this.contentComponentRef.instance);
        }

        if (_.isFunction(this.contentComponentRef.instance.cancel)) {
            this.cancelAction = this.contentComponentRef.instance.cancel.bind(this.contentComponentRef.instance);
        }

        if (_.isFunction(this.options.onContentComponentCreated)) {
            this.options.onContentComponentCreated(this.contentComponentRef.instance);
        }

        this.contentComponentRef.changeDetectorRef.detectChanges();
    }
}
