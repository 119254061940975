import { Component, computed, Input } from '@angular/core';
import { BaseInputComponent } from '../base-input/base-input.component';
import { DateTimeInputConfig } from '../input.type';

/**
 * Datetime input component.
 * @param format - The format of the date.
 * @param minDate - The minimum date.
 * @param maxDate - The maximum date.
 * @param disabledDates - The disabled dates.
 */
@Component({
    selector: 'app-datetime-input',
    templateUrl: './datetime-input.component.html',
    providers: [{ provide: BaseInputComponent, useExisting: DatetimeInputComponent }]
})
export class DatetimeInputComponent extends BaseInputComponent<DateTimeInput> {
    @Input() format: DateTimeInputConfig['format'] = 'dd. MM. yyyy HH:mm';
    @Input() minDate: DateTimeInputConfig['minDate'];
    @Input() maxDate: DateTimeInputConfig['maxDate'];
    @Input() disabledDates: DateTimeInputConfig['disabledDates'];

    dateValue = computed<Date>(() => {
        if (!this.value()) return null;
        if (this.value() instanceof Date) {
            return this.value() as Date;
        }
        const date = new Date(this.value());
        return isNaN(date.getTime()) ? null : date;
    });

    override onValueChange(value: DateTimeInput) {
        super.onValueChange(value);
        this.value.set(value);
    }
}

type DateTimeInput = Date | string;
